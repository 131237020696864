@use "/src/scss/abstracts" as *;

.card {
    padding: $spacing-600;
    box-shadow: $shadow-300;
    border: 1px solid $gray-200;
    border-radius: $border-radius-200;

    &-55 {
        max-width: 55rem;
    }

    &-header {
        background: unset;
        border-bottom: 1px solid $gray-200;
        padding: 0 0 $spacing-400 0;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;

        .section-title {
            padding-bottom: 0;
        }
    }

    &-actions {
        & > * + * {
            margin-left: 1rem;
        }
    }

    &-title {
        font-size: $type-size-500;
        font-weight: 700;
        margin: 0;
        padding: 0;

        i {
            font-size: $type-size-200;
            transform: translateY(-6px);
        }
    }

    &-body {
        padding-top: $spacing-500;

        & + .card-footer {
            margin-top: $spacing-500;
        }
    }

    &-footer {
        border-top: 1px solid rgba(black, 0.05);
        border-bottom: 1px solid rgba(black, 0.05);
        padding: 1.5rem 0;
        gap: 0.5rem;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
}
