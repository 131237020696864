@use "/src/scss/abstracts" as *;

.button-group {
    display: flex;
    background: $white;
    border-radius: 0.25rem;
    padding: 0.25rem;
    border: 1px solid rgba($black, 0.1);

    &__item {
        display: block;
        padding: 0.75rem 1rem 0.5em;
        color: $primary;
        background-color: $white;
        font-weight: bold;
        transition: all 200ms ease;
        border-radius: 0.25rem;
        border: 1;
        border-color: $primary;

        &:hover {
            cursor: pointer;
            color: $white;
            background-color: $primary;
        }

        &.active {
            background: $primary;
            color: $white;
            cursor: pointer;

            &:hover {
                color: $white;
            }
        }
    }
}
