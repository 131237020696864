$spacing-unit: 1rem;
$spacing-050: calc(0.125 * $spacing-unit);
$spacing-100: calc(0.25 * $spacing-unit);
$spacing-200: calc(0.5 * $spacing-unit);
$spacing-300: calc(0.75 * $spacing-unit);
$spacing-400: calc(1 * $spacing-unit);
$spacing-500: calc(1.5 * $spacing-unit);
$spacing-600: calc(2 * $spacing-unit);
$spacing-700: calc(3 * $spacing-unit);
$spacing-775: calc(3.75 * $spacing-unit);
$spacing-800: calc(4 * $spacing-unit);
$spacing-900: calc(6 * $spacing-unit);
$spacing-950: calc(7.5 * $spacing-unit);
$spacing-1000: calc(15 * $spacing-unit);
$spacing-2500: calc(25 * $spacing-unit);
