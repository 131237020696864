@use "/src/scss/abstracts" as *;

.form {
    &.grid-12 {
        gap: 2rem 1.5rem;
    }

    &__actions {
        border-top: 1px solid rgba($black, 0.1);
        padding-top: 0.5rem;
    }
}
